// src/components/Completion.tsx
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import paymentStore from '../../stores/PaymentStore';
import { Link } from 'react-router-dom';

const Completion: React.FC = observer(() => {
  const [messageBody, setMessageBody] = useState<React.ReactNode>('');

  useEffect(() => {
    const processStripeResponse = async () => {
      if (!paymentStore.stripe) return;
      const url = new URL(window.location.href);
      const paymentIntentClientSecret = url.searchParams.get('payment_intent_client_secret');
      const paymentIntentId = url.searchParams.get('payment_intent');
      const redirectStatus = url.searchParams.get('redirect_status');
  
      if (paymentIntentClientSecret) {
        const { error, paymentIntent } = await paymentStore.stripe.retrievePaymentIntent(paymentIntentClientSecret)
        paymentStore.updatePaymentStatusInDatabase(paymentIntent!.id, paymentIntentClientSecret, paymentIntent?.status as string);
        setMessageBody(
          error ? `> ${error.message}` : `Your payment ${paymentIntent?.status} 🎉`
        );

      }
    }
    processStripeResponse()
    }, [paymentStore.stripe]);

  return (
    <div className="flex flex-col items-center justify-center text-center min-h-screen bg-brown p-8">
      <h1 className="text-4xl md:text-5xl lg:text-6xl font-neris-semibold text-gold mb-4">Thank you!</h1>
      <p className="text-justify font-neris-thin text-gold text-lg md:text-xl lg:text-2xl mb-10">
        {messageBody || 'Please wait while we finish processing your payment.'}
      </p>
      <Link to="/" className="px-6 py-2 bg-green text-gold font-neris-light rounded-full hover:bg-opacity-90 transition-transform duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brown">
        Continue exploring more world class cafe product!
      </Link>
    </div>
  );
});

export default Completion;
