import React from 'react';

interface WeightSelectorProps {
    selectedWeight: number;
    onWeightChange: (weight: number) => void;
}

const weights = [360]; // Extendable array for different weights

export const WeightSelector: React.FC<WeightSelectorProps> = ({ selectedWeight, onWeightChange }) => (
    <div className="flex flex-col space-y-2 text-brown font-neris-semibold">
        <span>Net weight</span>
        <div className="flex items-center space-x-4">
            {weights.map(weight => (
                <div key={weight} className="flex items-center space-x-2">
                    <input 
                        type="radio" 
                        name="weight" 
                        id={`${weight}g`} 
                        value={weight} 
                        className="hidden" 
                        checked={selectedWeight === weight}
                        onChange={() => onWeightChange(weight)} 
                    />
                    <label htmlFor={`${weight}g`} className={`p-2 rounded-full ${selectedWeight === weight ? 'bg-brown' : 'bg-transparent border border-brown z-1'}`}></label>
                    <label htmlFor={`${weight}g`} className="cursor-pointer">{weight}g</label>
                </div>
            ))}
        </div>
    </div>
);

interface QuantitySelectorProps {
    quantity: number;
    increaseQuantity: () => void;
    decreaseQuantity: () => void;
}

export const QuantitySelector: React.FC<QuantitySelectorProps> = ({ quantity, increaseQuantity, decreaseQuantity }) => (
    <div className="flex flex-col items-start space-y-2 text-brown font-neris-semibold">
        <span>Quantity</span>
        <div className="flex items-center space-x-2 bg-brown rounded-full">
            <button onClick={decreaseQuantity} className="font-neris-semibold text-gold w-9 h-7 flex items-center justify-center hover:bg-opacity-90 transition duration-300 ease-in-out rounded-full z-1">-</button>
            <span className="font-neris-semibold text-gold px-2 w-9">{quantity}</span>
            <button onClick={increaseQuantity} className="font-neris-semibold text-gold w-9 h-7 flex items-center justify-center hover:bg-opacity-90 transition duration-300 ease-in-out rounded-full z-1">+</button>
        </div>
    </div>
);

export const ProductDescription: React.FC = () => (
    <>
        <h2 className="text-2xl text-left text-brown font-neris-semibold">MOUNTAIN SUNRISE</h2>
        <p className="text-justify font-neris-thin text-brown">
            Discover the essence of our high-altitude Colombian coffee, expertly crafted to deliver a symphony of flavors. 
            Each sip unveils notes of sweetness, accentuated by subtle hints of cocoa and a gentle fruit undertone. 
            This coffee offers a smooth and bright taste, capturing the tranquility of its mountainous origin.
        </p>
        <div className="flex flex-col space-y-0">
            <div className="text-brown font-neris-semibold">Brewing recommendations</div>
            <p className="text-brown font-neris-thin">Use between 7g to 12g per 3/4 cup.</p>
        </div>
    </>
);


// This component also does not require props as it manages its own display.
export const ProductImages: React.FC = () => (
    <div className="flex flex-col items-center space-y-4 w-full md:w-1/2 md:ml-[-25%] mb-4 md:mb-0">
        <img src="https://silver-stream-bucket.s3.amazonaws.com/puch.svg" alt="Coffee Pouch" className="w-3/4 h-auto mb-4" />
        <div className="flex space-x-4 w-full justify-center">
            <div className="relative w-1/3 h-64">
                <img src="https://silver-stream-bucket.s3.amazonaws.com/silver_-8.webp" alt="Mountain Sunrise" className="w-full h-full object-cover" />
                <div className="absolute inset-0 bg-white opacity-10"></div>
            </div>

            <div className="relative w-1/3 h-64">
                <img src="https://silver-stream-bucket.s3.amazonaws.com/mountain-sunrise-shop.webp" alt="Mountain Sunrise" className="w-full h-full object-cover" />
            </div>

            <div className="relative w-1/3 h-64">
                <img src="https://silver-stream-bucket.s3.amazonaws.com/silver_-10.webp" alt="Mountain Sunrise" className="w-full h-full object-cover" />
                <div className="absolute inset-0 bg-white opacity-10"></div>
            </div>
        </div>
    </div>
);


interface ProductPriceProps {
    price: number;
}

export const ProductPrice: React.FC<ProductPriceProps> = ({ price }) => (
    <div className="flex flex-col space-y-0">
        <div className="text-brown font-neris-semibold">Value</div>
        <p className="text-brown font-neris-thin -space-y-2">${price}</p>
    </div>
);

export const Leaves: React.FC = () => (
    <>
        <img src="https://silver-stream-bucket.s3.amazonaws.com/mountain_sunrise_leaf1.svg" alt="Leaf 1" className="absolute h-12 top-7 -right-2 z-10" />
        <img src="https://silver-stream-bucket.s3.amazonaws.com/mountain_sunrise_leaf2.svg" alt="Leaf 2" className="absolute h-14 top-1/3 -right-12 z-10" />
        <img src="https://silver-stream-bucket.s3.amazonaws.com/mountain_sunrise_leaf3.svg" alt="Leaf 3" className="absolute h-12 top-0 -right-12 z-10" />
           
    </>
)

export default ProductPrice;
