import React from 'react';
import './styles.css'

const FeatureBox: React.FC = () => {
    return (
        <div className="feature-box bg-gold shadow-gold-both w-full mx-auto max-w-md">
            {/* Scallop Containers */}
            <div className="scallop-container"></div>
            
            {/* Main Content */}
            <div className="feature-content py-4 flex flex-col items-center gap-0 w-full">
                {/* Line 1 */}
                <div className="flex justify-between w-full border-t border-b border-brown">
                    <div className="flex-1 p-2 flex flex-col justify-center border-r border-brown">
                        <span className="font-rosvelit text-xs text-left mb-1">Origin</span>
                        <span className="font-neris-semibold">COLOMBIA</span>
                    </div>
                    <div className="flex-1 p-2 flex flex-col justify-center border-brown">
                        <span className="font-rosvelit text-xs text-left mb-1">Height</span>
                        <span className="font-neris-semibold">5,740 ft.</span>
                    </div>
                </div>

                {/* Line 2 */}
                <div className="flex justify-between w-full border-b border-brown">
                    <div className="flex-2 p-2 flex flex-col justify-center border-r border-brown">
                        <span className="font-rosvelit text-xs text-left mb-1">Taste And Aroma</span>
                        <span className="font-neris-semibold">Velvety, Sun-Kissed, Mystique</span>
                    </div>
                    <div className="flex-1 p-2 flex flex-col justify-center">
                        <span className="font-rosvelit text-xs text-left mb-1">Roasting Type</span>
                        <span className="font-neris-semibold">Medium</span>
                    </div>
                </div>
            </div>

            <div className="scallop-container"></div>
        </div>
    );
}

export default FeatureBox;
