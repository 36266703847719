import React, { useEffect } from 'react';
import './App.css';
import AppRouter from './routes/AppRouter';
import initStore from './stores/InitStore';

const App: React.FC = () => {
  const {initializeApp} = initStore;
  useEffect(() => {
      initializeApp();
  }, [initializeApp]);
  
  return (
        <AppRouter />
  );
}

export default App;
