import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  // Placeholder state for the cart count
  const [cartCount, setCartCount] = React.useState(3);  // Example count

  return (
    <header className="bg-brown shadow-md py-4 md:py-6 header">
      <div className="container mx-auto flex flex-col items-center justify-between px-4 md:px-0">
        
        {/* Top Bar: Instagram and Cart */}
        <div className="flex justify-between w-full mb-2">
          {/* Instagram Link */}
          <a href="https://www.instagram.com/silver_stream_cafe" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
            <img src="https://silver-stream-bucket.s3.amazonaws.com/instagram.webp" alt="Instagram" className="h-6 md:h-8 w-6 md:w-8" />
            <span className="text-xs md:text-base font-neris-semibold text-gold hover:text-green">Follow Us</span>
          </a>

          {/* Cart Icon */}
          <div className="relative invisible">
            <img src="https://silver-stream-bucket.s3.amazonaws.com/shopping_cart.webp" alt="Cart" className="h-6 md:h-8 w-6 md:w-8 text-gold" />
            <span className="absolute -top-1 -right-1 bg-gold text-white text-xs rounded-full p-1.5 w-4 h-4 flex items-center justify-center">{cartCount}</span>
          </div>
        </div>

        {/* Logo */}
        <Link to={'/'}>
          <img src="https://silver-stream-bucket.s3.amazonaws.com/logo.webp" alt="Silver Stream Logo" className="max-h-24 md:max-h-40 mb-2 md:mb-4 -mt-4 md:-mt-6" />
        </Link>

        {/* Navigation Links */}
        <nav className="flex space-x-4 md:space-x-8 font-neris-semibold text-sm md:text-base text-gold mb-2">
          <Link to="/" className=" hover:text-green hover:underline">Home</Link>
          <Link to="/about-us" className=" hover:text-green hover:underline">About Us</Link>
          <Link to="/product" className=" hover:text-green hover:underline">Products</Link>
          <Link to="/contact-us" className="hover:text-green hover:underline">Contact Us</Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;