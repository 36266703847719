import React, { useEffect } from 'react';
export const AboutUsFeature = () => {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 300,
                left: 0,
                behavior: "smooth",
              });
        }, 250)
    }, [])
    

    return (
        <div className="relative h-screen bg-center bg-no-repeat bg-cover" style={{ 
            backgroundImage: "url('https://silver-stream-bucket.s3.amazonaws.com/silver_-7.webp')"
            // backgroundImage: `url(${backgroundImage})`
            }}>
            
            {/* Welcome Text */}
            <div className="absolute  inset-x-0 bottom-0 right-0 p-6 md:p-16 space-y-4 rounded-md bg-opacity-90 bg-gold">
                <h1 className="text-right mr-[10%] text-6xl md:text-8xl xl:mx-[22%] font-neris-semibold text-brown break-words">Welcome</h1>
                <p className="mr-[10%] ml-[10%] mb-[10%] text-sm md:text-xl xl:mx-[22%] font-neris-semibold text-brown break-words text-justify">
                    Our journey began as a dream to bring the finest Colombian coffee beans straight to our home, and now to yours. At Silver Stream, we are dedicated to sustainability and believe in paying fair wages to our coffee producers, who handpick every bean using responsible production practices. Our coffee is produced in small batches, ensuring the sustainability of the farms and a careful process to extract the unique qualities of artisan coffee. These beans are grown at the perfect altitude, resulting in a sweet and smooth flavor profile that characterizes the best of Colombian coffee. Join us on this journey and savor the unique taste of Silver Stream coffee.
                </p>
            </div>
            
        </div>
    );
}




