import React from 'react';
import { AboutUsFeature } from '../components/AboutPage/AboutUsFeature';

export const AboutPage: React.FC = () => {
    return (
        <>
            <AboutUsFeature />
        </>
    );
}
