import React from 'react';
import { BrowserRouter as Router, Route, Routes, ScrollRestoration } from 'react-router-dom';
import Header from '../components/Header';
import { HomePage } from './HomePage';
import Footer from '../components/Footer/';
import ProductPage from './ProductPage';
import {AboutPage} from './AboutPage';
import MountainSunrise from '../components/Products/MountainSunrise';
import {Contact} from './ContactPage';
import NewsletterConfirmation from '../components/Footer/NewsletterConfirmation';
import NewsletterRemoval from '../components/Footer/NewsletterRemoval';
import { RouteConfig } from '../types/Routes';
import CheckoutPage from '../components/Checkout';
import Completion from '../components/Checkout/Completion';
import { ToastContainer } from 'react-toastify';


export const routes: RouteConfig[] = [
  { path: '/', component: HomePage, priority: 1.0, changeFreq: 'weekly' },
  { path: '/product', component: ProductPage, priority: 0.8, changeFreq: 'weekly' },
  { path: '/about-us', component: AboutPage, priority: 0.7, changeFreq: 'monthly' },
  { path: '/mountain-sunrise', component: MountainSunrise, priority: 0.7, changeFreq: 'monthly' },
  { path: '/contact-us', component: Contact, priority: 0.7, changeFreq: 'monthly' },
  {
    path: '/confirm-newsletter/:code',
    component: NewsletterConfirmation,
    excludeFromSitemap: true
  },
  {
    path: '/remove-newsletter/:code',
    component: NewsletterRemoval,
    excludeFromSitemap: true
  },
  {
    path: '/cart', component: CheckoutPage, priority: .5, changeFreq: 'monthly'
  },
  {
    path: '/payment-processed', component: Completion, priority: .5, changeFreq: 'monthly'
  },
  
];


function AppRouter() {
  return (
    <Router>
      <div className='overflow-x-hidden'>
      <ToastContainer />
      <Header />
      <Routes>
      {routes.map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} />
        ))}
      </Routes>
      <Footer />
      </div>
    </Router>
  );
}

export default AppRouter;
