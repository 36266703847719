import React, { useEffect, useRef } from 'react';
import ProductFeature from './ProductFeature';

const ProductPage: React.FC = () => {
    const ref1 = useRef<HTMLImageElement>(null);
    const ref2 = useRef<HTMLImageElement>(null);
    
    useEffect(() => {
        setTimeout(() => {
            if(ref1.current){
                // mobile scroll 
                ref1.current.scrollIntoView({behavior: "smooth",});
            }
            if(ref2.current){
                ref2.current.scrollIntoView({behavior: "smooth",});

            }
        }, 250)
    }, [])

    return (
        <div className="">

            {/* Tri-fold Section */}
            <div className="flex flex-col w-full md:flex-row">
                <div className="w-full h-[40vh] md:h-auto md:w-[37%] order-1 md:order-1">
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/silver_-7.webp" alt="Arabica Coffee" className="w-full h-full object-cover" />
                </div>
                <div className="w-full h-[60vh] md:h-auto md:w-1/5 order-3 md:order-2">
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/silver_-12.webp" alt="Tea" className="w-full h-full object-cover" />
                </div>
                <div className="w-full md:w-[43%] bg-green p-10 md:p-3 relative flex flex-col items-center text-center order-2 md:order-3">
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/mountain_sunrise_logo.webp" alt="Mountain Sunrise Logo" className="mb-0 mt-[10%] left-6 h-[15vh] md:h-[30%]" />
                     <p className="mt-[10%] md:mt-[10%] ml-4 md:ml-[10%] md:mr-[10%] z-0 font-neris-thin overflow-hidden text-justify">Discover the essence of our high-altitude Colombian coffee, expertly crafted to deliver a symphony of flavors. Each sip unveils notes of sweetness, accentuated by subtle hints of cocoa and a gentle fruit undertone. This coffee offers a smooth and bright taste, capturing the tranquility of its mountainous origin.</p>
                </div>
            </div>
                <ProductFeature ref1={ref1} ref2={ref2} />
        </div>
    );
}

export default ProductPage;
