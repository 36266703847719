// src/components/CheckoutPage.tsx
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'; // Assuming CheckoutForm is also TypeScript compatible
import { cartStore } from '../../stores/CartStore';
import paymentStore from '../../stores/PaymentStore';
import { motion } from 'framer-motion';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

interface CartDisplayProps {
    onNext: () => void;
  }
  
  interface AddressFormProps {
    onNext: () => void;
  }
  
  interface CheckoutFormContainerProps {
    clientSecret: string;
  }
  
  const CartDisplay: React.FC<CartDisplayProps> = ({ onNext }) => {
    return (
        <div className="bg-white p-4 shadow-lg rounded-lg w-full md:w-1/2">
            {cartStore.cartItems.map((item) => (
                <div key={item.id} className="flex justify-between items-center mb-4 p-4 rounded shadow">
                    <img src={item.imageUrl} alt={item.name} style={{ width: "100px" }} />
                    <div>
                        <h5 className="font-neris-semibold text-brown">{item.name}</h5>
                        <p className="text-green">${item.price} x {item.quantity}</p>
                        <div className="flex space-x-2">
                            <button onClick={(e) => { e.stopPropagation(); cartStore.updateQuantity(item.id, item.quantity - 1); }}
                                    className="font-neris-semibold text-gold w-9 h-7 flex items-center justify-center hover:bg-opacity-90 transition duration-300 ease-in-out rounded-full z-1">-</button>
                            <span className="font-neris-semibold text-gold px-2 w-9">{item.quantity}</span>
                            <button onClick={(e) => { e.stopPropagation(); cartStore.updateQuantity(item.id, item.quantity + 1); }}
                                    className="font-neris-semibold text-gold w-9 h-7 flex items-center justify-center hover:bg-opacity-90 transition duration-300 ease-in-out rounded-full z-1">+</button>
                            <button onClick={(e) => { e.stopPropagation(); cartStore.removeFromCart(item.id); }}
                                    className="text-red-500">Remove</button>
                        </div>
                    </div>
                </div>
            ))}
            <h2 className="font-neris-semibold text-xl text-brown">Total: ${cartStore.total.toFixed(2)}</h2>
            <button onClick={(e) => { e.stopPropagation(); onNext(); }}
                    className="mt-4 bg-green text-white font-bold py-2 px-4 rounded hover:bg-dark_gold transition-colors duration-200 ease-in-out">
                Proceed to Shipping
            </button>
        </div>
    );
};
  

interface CheckoutFormContainerProps {
    clientSecret: string;
}

const CheckoutFormContainer: React.FC<CheckoutFormContainerProps> = observer(({ clientSecret }) => {
    const elementsOptions = {
        clientSecret: clientSecret,
    };

    return (
        <div className='relative bg-white p-4 shadow-lg rounded-lg w-full md:w-1/2'>
            {clientSecret && (
                <Elements stripe={paymentStore.stripe} options={elementsOptions} key={clientSecret}>
                    <CheckoutForm />
                </Elements>
            )}
        </div>
    );
});
const AddressForm: React.FC<AddressFormProps> = ({ onNext }) => {
    const [isValid, setIsValid] = useState(true);
    const [formData, setFormData] = useState({
        fullName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        stateOrRegion: '',
        postalCode: '',
        country: ''
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const validateForm = () => {
        // Example validation logic
        const isValid = formData.fullName.trim() !== '' &&
                        formData.addressLine1.trim() !== '' &&
                        formData.city.trim() !== '' &&
                        formData.stateOrRegion.trim() !== '' &&
                        formData.postalCode.trim() !== '' &&
                        formData.country.trim() !== '';
        setIsValid(isValid);
        return isValid;
    };

    const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (validateForm()) {
            paymentStore.setAddress(formData);
            onNext();
        }
    };
    
    return (
        <div className="p-4 shadow-lg rounded-lg bg-brown">
            <h3 className="font-neris-semibold text-lg text-white mb-4">Shipping Address</h3>
            <input type="text" name="fullName" placeholder="Full Name" className="border p-2 rounded w-full mb-2"
                   onChange={handleInputChange} onClick={(e) => e.stopPropagation()} />
            <input type="text" name="addressLine1" placeholder="Address Line 1" className="border p-2 rounded w-full mb-2"
                   onChange={handleInputChange} onClick={(e) => e.stopPropagation()} />
            <input type="text" name="addressLine2" placeholder="Address Line 2" className="border p-2 rounded w-full mb-2"
                   onChange={handleInputChange} onClick={(e) => e.stopPropagation()} />
            <input type="text" name="city" placeholder="City" className="border p-2 rounded w-full mb-2"
                   onChange={handleInputChange} onClick={(e) => e.stopPropagation()} />
            <input type="text" name="stateOrRegion" placeholder="State/Province/Region" className="border p-2 rounded w-full mb-2"
                   onChange={handleInputChange} onClick={(e) => e.stopPropagation()} />
            <input type="text" name="postalCode" placeholder="Postal Code" className="border p-2 rounded w-full mb-2"
                   onChange={handleInputChange} onClick={(e) => e.stopPropagation()} />
            <input type="text" name="country" placeholder="Country" className="border p-2 rounded w-full mb-4"
                   onChange={handleInputChange} onClick={(e) => e.stopPropagation()} />
            <button onClick={handleNext}
                    disabled={!isValid}
                    className="bg-green text-white font-bold py-2 px-4 rounded hover:bg-dark_gold transition-colors duration-200 ease-in-out">
                Next
            </button>
            {!isValid && <p className="text-red-500">Please fill in all reqired fields.</p>}
        </div>
    );
};



  const CheckoutPage: React.FC = observer(() => {
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        paymentStore.setClientSecret(undefined);
        paymentStore.fetchClientSecret(cartStore.total);
    }, []);


    const toggleExpand = (section: number) => {
        setCurrentStep(section); // Sets the current step and implicitly manages which section is expanded
    };
    const isExpanded = (section: number) => currentStep === section;


    // Summarize cart and address information for previews
    const cartSummary = `${cartStore.count} item${cartStore.count > 1 ? 's' : ''} - $${cartStore.total.toFixed(2)}`;
    const addressSummary = paymentStore.address.fullName !== ''? `${paymentStore.address.fullName}, ${paymentStore.address.addressLine1}${paymentStore.address.addressLine2 ? ', ' + paymentStore.address.addressLine2 : ''}, ${paymentStore.address.city}, ${paymentStore.address.stateOrRegion}, ${paymentStore.address.postalCode}, ${paymentStore.address.country}` : '';

    return (
        <div className="relative bg-gold justify-center min-h-screen p-10">
            <div className="flex flex-col space-y-4">
                <div className={`cursor-pointer bg-brown rounded-lg shadow-md text-gold p-4 font-neris font-semibold ${isExpanded(0) ? '' : 'mb-4'}`}>
                    <div onClick={() => toggleExpand(0)}
                         className="flex justify-between items-center">
                        <h3 className="text-lg">Cart</h3>
                        {isExpanded(0) ? <ChevronUpIcon className="w-6 h-6" /> : <ChevronDownIcon className="w-6 h-6" />}
                    </div>
                    <motion.div initial={{ maxHeight: 0 }}
                                animate={{ maxHeight: isExpanded(0) ? '100vh' : '0' }}
                                transition={{ duration: 0.5 }}
                                className="overflow-hidden flex flex-col items-center justify-center">
                        {isExpanded(0) && <CartDisplay onNext={() => toggleExpand(1)} />}
                        {!isExpanded(0) && <p className="pt-4">{cartSummary}</p>}
                    </motion.div>
                </div>
    
                <div className={`cursor-pointer bg-brown rounded-lg shadow-md text-gold p-4 font-neris font-semibold ${isExpanded(1) ? '' : 'mb-4'}`}>
                    <div onClick={() => toggleExpand(1)}
                         className="flex justify-between items-center">
                        <h3 className="text-lg">Address</h3>
                        {isExpanded(1) ? <ChevronUpIcon className="w-6 h-6" /> : <ChevronDownIcon className="w-6 h-6" />}
                    </div>
                    <motion.div initial={{ maxHeight: 0 }}
                                animate={{ maxHeight: isExpanded(1) ? '100vh' : '0' }}
                                transition={{ duration: 0.5 }}
                                className="overflow-hidden flex flex-col items-center justify-center">
                        {isExpanded(1) && <AddressForm onNext={() => toggleExpand(2)} />}
                        {!isExpanded(1) && <p className="pt-4">{addressSummary}</p>}
                    </motion.div>
                </div>
    
                <div className={`cursor-pointer bg-brown rounded-lg shadow-md text-gold p-4 font-neris font-semibold ${isExpanded(2) ? '' : 'mb-4'}`}>
                    <div onClick={() => toggleExpand(2)}
                         className="flex justify-between items-center">
                        <h3 className="text-lg">Payment</h3>
                        {isExpanded(2) ? <ChevronUpIcon className="w-6 h-6" /> : <ChevronDownIcon className="w-6 h-6" />}
                    </div>
                    <motion.div initial={{ maxHeight: 0 }}
                                animate={{ maxHeight: isExpanded(2) ? '100vh' : '0' }}
                                transition={{ duration: 0.5 }}
                                className="overflow-hidden flex flex-col items-center justify-center py-2">
                        {isExpanded(2) && paymentStore.clientSecret && <CheckoutFormContainer clientSecret={paymentStore.clientSecret} />}
                    </motion.div>
                </div>
            </div>
        </div>
    );  
});

export default CheckoutPage;
