import { makeAutoObservable, reaction } from "mobx";

export interface CartItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  weight?: number;
  imageUrl: string;
}

class CartStore {
  cartItems: CartItem[] = [];

  constructor() {
    makeAutoObservable(this);
    this.loadCart();  // Load cart from local storage on initialization

    // React to changes in cartItems and update local storage
    reaction(
      () => this.cartItems.map(item => [item.quantity, item.price]),
      () => {
        localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
      }
    );
  }    

  loadCart() {
    const storedCart = localStorage.getItem('cartItems');
    if (storedCart) {
      this.cartItems = JSON.parse(storedCart);
    }
  }

  addToCart(item: CartItem) {
      const existingItem = this.cartItems.find(cartItem => cartItem.id === item.id && cartItem.weight === item.weight);
      if (existingItem) {
          existingItem.quantity += item.quantity;
      } else {
          this.cartItems.push({...item, quantity: item.quantity});
      }
  }

  removeFromCart(id: string) {
    this.cartItems = this.cartItems.filter(item => item.id !== id);
    this.cartItems = [...this.cartItems]; // Reassign to ensure MobX reactivity
}


  updateQuantity(id: string, quantity: number) {
    const index = this.cartItems.findIndex(item => item.id === id);
    if (index > -1 && quantity > 0) {
        this.cartItems[index].quantity = quantity;
        this.cartItems = [...this.cartItems]; // Ensuring MobX reacts to the change
    }
}


  updateWeight(id: string, newWeight: number) {
      const item = this.cartItems.find(item => item.id === id);
      if (item) {
          item.weight = newWeight;
          // Assume a method to recalculate the price based on the new weight
          item.price = this.calculatePriceBasedOnWeight(newWeight);
      }
  }

  calculatePriceBasedOnWeight(weight: number): number {
      // Example: Price calculation based on weight
      if (weight === 360) return 29;
      if (weight === 500) return 33; // Assuming a different weight option
      return 29; // Default price if no specific weight matched
  }

  get total() {
      return this.cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
  }

  get count() {
      return this.cartItems.reduce((sum, item) => sum + item.quantity, 0);
  }
}

export const cartStore = new CartStore();
