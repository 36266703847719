// stores/NewsletterStore.ts

import { action, makeAutoObservable, runInAction } from 'mobx';
import API from '../client';

type NewsletterResponse = {
    data : {
        status: 'success' | 'info' | 'error';
        message: string;
    }   
}

class NewsletterStore {
    
    constructor() {
        makeAutoObservable(this); 
    }

    @action.bound
    async confirmNewsletter(code: string): Promise<NewsletterResponse> {
        return runInAction(async () => {
            try {
                const response = await API.get<NewsletterResponse, NewsletterResponse>(`/api/contact-us/confirm-newsletter/${code}/`);
                return response;
            }
            catch(error){
                return {data: {status: 'error', message: 'There was an error. Please check your email and try again.'}}
            }
        });
    }
        
    @action.bound
    async subscribeToNewsletter(email: string): Promise<string> {
        return runInAction(async () => {
            try {
                const response = await API.post('/api/contact-us/create-newsletter-opt-in/', { email });
                if (response.status === 201 || response.status === 200) {
                    return 'Thanks for subscribing to our newsletter!';
                } else {
                    return 'There was an issue. Please try again.';
                }
            } catch (error) {
                return 'There was an error. Please check your email and try again.';
            }
        });
    }

    @action.bound
    async unsubscribeFromNewsletter(code: string): Promise<string> {
        return runInAction(async () => {
            try {
                const response = await API.get(`/api/contact-us/unsubscribe-newsletter/${code}/`);

                if (response.data.status === 'success') {
                    return response.data.message; // 'You have been successfully unsubscribed.'
                } else {
                    return response.data.message; // e.g. 'Code not found.'
                }
            } catch (error) {
                return 'There was an error. Please check your removal code and try again.';
            }
        });
    }
}
const newsletterStore = new NewsletterStore();
export default newsletterStore;
