import React, { FC } from "react";
import { Link } from "react-router-dom";

const HomePageHero: FC = () => {
    return (
        <section className="hero bg-dark_gold flex items-center justify-center relative overflow-hidden" aria-label="Introduction">
            <div className="container mx-auto flex flex-col items-center justify-center text-center p-4">
                <h1 className="text-5xl lg:text-8xl text-brown font-neris-semibold mb-4 relative">
                    Welcome
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/leaf2.svg" alt="Leaf 2" className="absolute h-20 lg:h-24 -right-32 lg:-right-40 -top-4 lg:-top-4" />
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/leaf3.svg" alt="Leaf 3" className="absolute h-16 lg:h-24 -right-64 lg:-right-80 -top-6 lg:-top-4" />
                </h1>
                <p className="font-neris-light text-base lg:text-lg leading-relaxed relative">
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/leaf1.svg" alt="Leaf 1" className="absolute h-20 lg:h-24 -left-36 lg:-left-44 -top-3 lg:-top-3" />
                    Dive into the experience, let your dreams take flight, and let your true potential shine through.
                </p>
                <p className="font-neris-semibold mb-6 text-base lg:text-lg leading-relaxed">
                    Elevate Your Day. Unlock Yourself.
                </p>
                <Link to="/product">
                    <button className="px-6 py-2 lg:px-8 lg:py-2 bg-brown text-gold rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brown">
                        Learn more
                    </button>
                </Link>
            </div>
        </section>
    );
}

export default HomePageHero;
