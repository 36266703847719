import React from 'react';
import FeatureBox from './FeatureBox';
import { Link } from 'react-router-dom';

const CenterFeature = () => {
    return (
        <div className="bg-green pt-4 flex flex-col items-center justify-center space-y-4 py-8 px-2 md:px-8"> {/* Added responsive padding */}
            {/* Mountain Sunrise Logo */}
            <img src="https://silver-stream-bucket.s3.amazonaws.com/mountain_sunrise_logo.webp" alt="Mountain Sunrise Logo" className="mb-4 pb-4" />

            {/* Coffee Features */}
            <FeatureBox />

            {/* Shop Now Button */}
            <Link to="/mountain-sunrise">
                <button className="w-44 py-2.5 bg-brown text-gold rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brown">
                    Shop Now
                </button>
            </Link>


            {/* Brew Information Text */}
            <p className="mt-4 text-sm text-brown font-neris-semibold">Brewing recommendation</p>
            <p className="text-xs text-brown neris-thin">use between 7g to 12g per 3/4 cup.</p>
        </div>
    );
}

export default CenterFeature;
