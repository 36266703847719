import React from 'react';
import HomePageHero from '../components/HomePage/HomePageHero';
import HomePageFeature from '../components/HomePage/HomePageFeature';

export const HomePage: React.FC = () => {
    return (
        <>
            <HomePageHero />
            <HomePageFeature />
        </>
    );
}
