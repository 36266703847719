import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import NewsletterStore from '../../stores/NewsletterStore';

const NewsletterConfirmation: React.FC = observer(() => {
    const { code } = useParams();
    const {confirmNewsletter} = NewsletterStore;
    const [confirmationStatus, setConfirmationStatus] = useState<string | null>(null);
    
    useEffect(() => {
        if (code) {
            confirmNewsletter(code)
                .then((response) => {
                    if (response.data.status === "success") {
                        setConfirmationStatus("success");

                    } else {
                        setConfirmationStatus("error");
                    }
                })
                .catch(() => {
                    setConfirmationStatus("error");
                });
        }
    }, [code, confirmNewsletter]);

    return (
        <div>
            {!!!confirmationStatus && "One Moment Please."}
            {confirmationStatus === "success" && <p>Thank you for confirming your subscription!</p>}
            {confirmationStatus === "error" && <p>Something went wrong. Please try again.</p>}
        </div>
    );
});

export default NewsletterConfirmation;
