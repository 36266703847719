import { observer } from 'mobx-react-lite';
import { cartStore } from '../../../stores/CartStore';
import { useNavigate } from 'react-router-dom';

const CartPreview = observer(() => {
    const navigate = useNavigate();

    const clearCart = () => {
        cartStore.cartItems = [];  // Reset the cart items array
    };

    return (
        <div className="bg-brown text-gold rounded-lg p-4 shadow-lg">
            <h3 className="font-bold text-xl mb-2">Cart ({cartStore.count} items)</h3>
            <div className="font-medium">Total: ${cartStore.total.toFixed(2)}</div>
            <div className="flex space-x-4 mt-4">
                <button 
                    onClick={() => navigate('/cart')}
                    className="bg-green text-gold font-bold py-2 px-4 rounded hover:bg-green-700 transition-colors duration-200 ease-in-out">
                    Go to Cart
                </button>
                <button 
                    onClick={clearCart}
                    className="bg-red-500 text-gold font-bold py-2 px-4 rounded hover:bg-red-700 transition-colors duration-200 ease-in-out">
                    Clear Cart
                </button>
            </div>
        </div>
    );
});

export default CartPreview;
