import React from 'react';
import MountainSunriseProduct from '../components/Product/MountainSunriseProduct';

const ProductPage: React.FC = () => {
    return (
        <MountainSunriseProduct />
    );
}

export default ProductPage;
