import React, { useState, FC } from 'react';
import NewsletterStore from '../../stores/NewsletterStore';

// Define types for the getCookie function
function getCookie(name: string): string | null {
    let cookieValue: string | null = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const CSRFToken: FC = () => {
    const csrftoken = getCookie('csrftoken') || ''; // Default to empty string if null
    return (
        <input type="hidden" name="csrfmiddlewaretoken" value={csrftoken} />
    );
};

const Footer: FC = () => {
    const { subscribeToNewsletter } = NewsletterStore;

    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const handleSubmitNewsletter = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');

        try {
            const responseMessage: string = await subscribeToNewsletter(email);
            setMessage(responseMessage);
        } catch (err) {
            console.error("Error subscribing to newsletter:", err);
            setMessage('Error occurred. Please try again.');
        }

        setLoading(false);
    };

    return (
        <footer className="relative overflow-hidden bg-brown text-dark_gold py-4 md:h-[35vh]">
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 px-4 md:px-0 space-y-4">

                {/* Column 1: Logo & Instagram */}
                <div className="relative flex flex-col items-center md:items-start z-20">
                <img src="https://silver-stream-bucket.s3.amazonaws.com/footer_leaf1.svg" alt="Leaf 1" className="absolute top-6 md:top-8 lg:top-12 left-6 md:left-28 lg:left-28 h-8 md:h-12 lg:h-16" />
                <img src="https://silver-stream-bucket.s3.amazonaws.com/logo.webp" alt="Logo" className="h-24 mb-4" />
                    <a href="https://www.instagram.com/silver_stream_cafe" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                        <img src="https://silver-stream-bucket.s3.amazonaws.com/instagram.webp" alt="Instagram" className="h-8 w-8" />
                        <span className="text-sm md:text-base font-neris-semibold text-gold hover:text-green">Follow Us</span>
                    </a>
                </div>

                {/* Column 2: Contact Info */}
                <div className="relative">
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/footer_leaf2.svg" alt="Leaf 2" className="absolute top-4 md:top-6 lg:top-10 -left-8 md:-left-12 lg:-left-24 h-10 md:h-12 lg:h-16" />
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/footer_leaf3.svg" alt="Leaf 3" className="invisible md:visible absolute -bottom-12 md:-bottom-20 lg:-bottom-44 right-8 md:right-12 lg:right-24 h-10 md:h-12 lg:h-16" />
                    <h2 className="font-neris-semibold mb-8 text-xl">Contact info</h2>
                    <p className="mb-4 font-neris-light">info@silverstream.cafe</p>
                    <p className='font-neris-light'>silverstream.cafe</p>
                </div>

                {/* Column 3: Newsletter */}
                <div className="relative">
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/footer_leaf4.svg" alt="Leaf 4" className="hidden md:block absolute h-12 md:h-16 lg:h-20 top-40 left-10 md:-left-12"/>
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/footer_leaf5.svg" alt="Leaf 5" className="hidden md:block absolute h-16 md:h-20 lg:h-24 -bottom-8 lg:-bottom-12 -right-12"/>
                    <h2 className="font-neris-semibold text-xl space-y-96">Newsletter</h2>
                    <form onSubmit={handleSubmitNewsletter}>
                        <CSRFToken />
                        <div className="top-4 relative rounded-full overflow-hidden border-4 border-gold">
                            <label htmlFor="newsletter-email" className="sr-only">Enter your email for our newsletter</label>
                            <input 
                                id="newsletter-email"
                                type="email" 
                                placeholder="Your Email" 
                                className="bg-white font-neris-semibold bg-transparent w-full p-2 pl-4 pr-20"
                                aria-required="true"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button type="submit" disabled={loading} className="absolute inset-y-0 right-0 bg-gold text-brown font-neris-light px-3 py-2 rounded-full flex items-center justify-center">
                                {loading ? 'Submitting...' : 'Sign Up'}
                            </button>
                        </div>
                    </form>

                    {message && <p className="mt-4 absolute text-center font-neris-semibold text-sm">{message}</p>}
                </div>


                {/* Column 4: Copyright */}
                <div className="relative text-center mt-4">
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/footer_leaf6.svg" alt="Leaf 6" className="absolute bottom-16 md:bottom-20 lg:bottom-20 -right-4 md:-right-6 lg:-right-12 h-10 md:h-12 lg:h-16" />
                    <p className='font-neris-semibold text-gold text-xs mt-8'>Distributed by </p> 
                    <p className='font-neris-light text-xs'>Silver Stream L.L.C. Santa Barbara, California 93105</p>
                    <p className="pt-2 font-neris-semibold text-center text-gold text-sm">© 2023 Silver Stream L.L.C.</p>
                </div>
            </div>
            {/* Overlays */}
            <img src="https://silver-stream-bucket.s3.amazonaws.com/footer_cute1.svg" alt="Cafe Plant 1" className="md:block absolute top-4 md:top-32 lg:top-32 right-96 md:-left-44 lg:-left-44 h-[25%] md:h-[50vh] lg:h-[70vh] z-10 opacity-70" />
            <img src="https://silver-stream-bucket.s3.amazonaws.com/footer_cute2.svg" alt="Cafe Plant 2" className="md:block absolute top-40 md:top-40 lg:top-40 -right-56 md:-right-24 lg:-right-24 md:h-[30vh] lg:h-[35vh] z-10 opacity-70" />
        
        </footer>
    );
}

export default Footer;
