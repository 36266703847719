// NewsletterRemoval.tsx

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import newsletterStore from '../../stores/NewsletterStore';

const NewsletterRemoval: React.FC = () => {
    const { code } = useParams<{ code: string }>(); // Extracting email from the URL
    const [message, setMessage] = useState<string>('Processing...');

    useEffect(() => {
        async function unsubscribe() {
            if(code){
                try {
                    const responseMessage = await newsletterStore.unsubscribeFromNewsletter(code);
                    setMessage(responseMessage);
                } catch (error) {
                    setMessage('There was an unexpected error. Please try again later.');
                }
            }
        }
         
        unsubscribe();
    }, [code]);

    return (
        <div>
            <h2>Newsletter Removal</h2>
            <p>{message}</p>
        </div>
    );
}

export default NewsletterRemoval;
