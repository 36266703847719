// stores/InitStore.ts
import { action, makeAutoObservable } from 'mobx';
import API from '../client';

class InitStore {
    constructor() {
        makeAutoObservable(this); 
    }

    @action.bound
    async initializeApp() {
        try {
             await API.get('/api/init-app/');
        } catch (error) {
            console.error("Error initializing app:", error);
        }
    }
}

const initStore = new InitStore();
export default initStore;
