import { makeObservable, observable, action, computed, runInAction } from "mobx";
import { Contact } from "../types/ContactUs";
import API from "../client";

type statusType = "idle" | "loading" | "success" |"error";

export class ContactUsStore {
    @observable inquiries: Contact[] = observable.array([]);
    @observable status: statusType = "idle";
    @observable error: string | null = null;
    @observable name: string = '';
    @observable email: string = '';
    @observable message: string = '';
    @observable phone: string = ''

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    @action.bound
    setEmail(email: string) {
        this.email = email;
    }

    @action.bound
    setPhone(phone: string){
        this.phone = phone;
    }

    @action.bound
    setMessage(message: string) {
        this.message = message;
    }

    @action.bound
    setStatus(status: statusType) {
        this.status = status;
    }

    @computed
    get canSubmit() {
        return !!this.name && !!this.email && !!this.message;
    }

    @action.bound
    async createInquiry(inquiryData: Contact) {
        this.status = "loading";
        try {
            const response = await API.post<Contact>("/api/contact-us/create-inquiry/", inquiryData);
            runInAction(() => {
                this.inquiries.push(response.data);
                this.status = "success";
            });
        } catch (error: unknown) {
            runInAction(() => {
                this.status = "error";
                if (error instanceof Error) {
                    this.error = error.message;
                } else {
                    this.error = "An error occurred.";
                }
            });
        }
    }

    @action.bound
    async fetchInquiries() {
        this.status = "loading";
        try {
            const response = await API.get<Contact[]>("/api/contact-us/manage-inquiry/");
            runInAction(() => {
                this.inquiries = response.data;
                this.status = "success";
            });
        } catch (error: unknown) {
            runInAction(() => {
                this.status = "error";
                if (error instanceof Error) {
                    this.error = error.message;
                } else {
                    this.error = "An error occurred.";
                }
            });
        }
    }
}

const contactUsStore = new ContactUsStore();
export default contactUsStore;
