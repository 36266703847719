import React from 'react';
import CenterFeature from './CenterFeature';

const HomePageFeature = () => {
    return (
        <section className="features">
            <div className="flex-col md:flex md:flex-row w-full h-full">

                {/* Feature 1 */}
                <div className="flex-1 flex-shrink-0 bg-center bg-cover relative h-56 md:h-auto" style={{ backgroundImage: 'url(https://silver-stream-bucket.s3.amazonaws.com/steamy.webp)' }}>
                {/* <div className="flex-1 flex-shrink-0 bg-center bg-cover relative h-56 md:h-auto" style={{ backgroundImage: 'url(https://silver-stream-bucket.s3.amazonaws.com/steamy.webp)' }}> */}
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/home_coffee_arabica_symbol.webp" alt="Certified Arabica Colombian Café" className="float-right pt-8 pr-8 w-auto h-auto md:pl-8 md:float-left"></img>
                </div>

                {/* Feature 2 */}
                <div className="flex-1 flex-grow md:h-auto overflow-y-auto">
                    <CenterFeature />
                </div>

                {/* Feature 3 */}
                {/* <div className="flex-1 flex-shrink-0 bg-center bg-cover h-56 md:h-auto overflow-y-auto" style={{ backgroundImage: 'url(https://silver-stream-bucket.s3.amazonaws.com/beans-in-bag.webp)' }}> */}
                <div className="flex-1 flex-shrink-0 bg-center bg-cover h-56 md:h-auto overflow-y-auto" style={{ backgroundImage: 'url(https://silver-stream-bucket.s3.amazonaws.com/silver_-10.webp)' }}>
                </div>

            </div>
        </section>
    );
}

export default HomePageFeature;
