// src/components/CheckoutForm.tsx
import React from 'react';
import { PaymentElement, useElements } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react-lite';
import paymentStore from '../../stores/PaymentStore';
import { toast } from 'react-toastify';

const CheckoutForm: React.FC = observer(() => {
  const elements = useElements();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!paymentStore.address.fullName || !elements) {
      toast.error('Please enter an address for shipping.')
      return;
  }
    if (elements) {
      paymentStore.confirmPayment(elements);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button onClick={(e) => e.stopPropagation()} className="relative z-20 mt-5 w-1/2 m-[25%] bg-black text-white rounded px-4 py-2 hover:bg-heritage-green" disabled={paymentStore.isLoading || !paymentStore.stripe || !elements} id="submit">
        {paymentStore.isLoading ? <div className="spinner" id="spinner"></div> : "Pay Now"}
      </button>
      {paymentStore.message && <div id="payment-message">{paymentStore.message}</div>}
    </form>
  );
});

export default CheckoutForm;
