import axios from 'axios'

const API = axios.create(
  {
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true
  })

function getCSRFTokenFromCookie() {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // Assuming csrftoken is the name of the cookie where CSRF token is stored
          if (cookie.startsWith('csrftoken=')) {
              cookieValue = decodeURIComponent(cookie.split('=')[1]);
              break;
          }
      }
  }
  return cookieValue;
}

API.interceptors.request.use((config) => {
  // Get the CSRF token from the cookie
  const csrfToken = getCSRFTokenFromCookie();
  if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default API;