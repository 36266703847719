import React from 'react';
import { useNavigate } from 'react-router-dom';

type ProductFeatureProps = {
    ref1: React.RefObject<HTMLImageElement>;
    ref2: React.RefObject<HTMLImageElement>;
  };
  

  const ProductFeature: React.FC<ProductFeatureProps> = ({ ref1, ref2 }) => {
    const navigate = useNavigate();
    
    return (
        <div className="py-32 px-4 bg-gold">

            <div className='relative flex md:hidden justify-center -mt-16'>
                <img
                    ref={ref1}
                    src="https://silver-stream-bucket.s3.amazonaws.com/product_logo.svg"
                    alt="Product Logo"
                    className='w-[50%]' 
                /> 
            </div>

            {/* Centered SVG Image with Text Elements md to large screens*/}
            <div className=" hidden md:flex relative flex justify-center items-center mt-16 mb-4 text-gold">
   
                <img 
                    src="https://silver-stream-bucket.s3.amazonaws.com/product_release_of_stress.svg" 
                    alt="Product Release of Stress" 
                    className="w-full lg:w-3/4"
                />

                <div ref={ref2} className="overflow-y-auto absolute w-1/4 -top-[1/4] right-[42%] lg:w-1/5 lg:-top-1/4 lg:right-[42%] z-20">
                    <picture>
                        {/* <source srcSet="/images/pouch.jp2" type="image/jp2" /> */}
                        <img src="https://silver-stream-bucket.s3.amazonaws.com/puch.svg" alt="Pouch" className="w-full h-auto" />
                    </picture>
                </div>

                {/* Text Elements - Adjust top and left/right values as needed */}
                <div className="z-20 absolute top-[19%] left-[73%] sm:left:[-50%] sm:top-[20%]  md:top-[22%]  md:left-[73%] lg:top-[20%] lg:left-[67%] font-neris-semibold">
                    <p className='text-xxs sm:text-sm md:text-md lg:text-md font-rosvelit'>Origin</p>
                    <p className='text-xxs md:text-sm lg:text-md'>COLOMBIA</p>
                </div>
                <div className="z-20 absolute top-[49%] md:top-[49%] left-[81%] md:left-[81%] lg:top[-60%] lg:left-[73%] font-neris-semibold sm:font-xs">
                    <p className='text-xxs sm:text-xs md:text-sm lg:text-md font-rosvelit'>Taste and Aroma</p>
                    <div className='text-xxs sm:text-xs md:text-md lg:text-md'>
                        <p>Velvety,</p>
                        <p>Sun-Kissed,</p>
                        <p>Mystique</p>
                    </div>
                </div>
                <div className="z-20 absolute left-[70%] top-[76%] sm:top-[76%] sm:left-[70%] md:top-[76%] md:left-[70%] lg:left-[65%] font-neris-semibold">
                    <p className='text-xxs sm:text-xs md:text-md lg:text-md font-rosvelit'>Altitude</p>
                    <p className='ml-4 text-xxs sm:text-xs md:text-sm lg:text-md'>5,740 ft.</p>
                </div>
            </div>

             {/* Centered SVG Image with Text Elements for small screens only*/}
             <div className="md:hidden relative flex justify-center items-center mt-16 mb-8 text-gold mb-8" style={{ height: '46.5vh' }}>
                <img
                    src="https://silver-stream-bucket.s3.amazonaws.com/Product_hoops_1.svg" 
                    alt="product hoops" 
                    className="w-full h-full object-cover"
                />
                <div className=" absolute w-[75%] -top-[16%] right-[42%] z-20">
                    <picture>
                        {/* <source srcSet="/images/pouch.jp2" type="image/jp2" /> */}
                        <img src="https://silver-stream-bucket.s3.amazonaws.com/puch.svg" alt="Pouch" className="w-full max-h-[60vh] min-h-[40vh]" />
                    </picture>  
                </div>

                {/* Text Elements - Adjust top and left/right values as needed */}
                <div className='z-20 absolute w-[8%] top-[13%] left-[55%]'>
                    <img 
                        src="https://silver-stream-bucket.s3.amazonaws.com/Product_country.svg" 
                        alt="country" 
                        className="w-full h-full"
                    />
                </div>
                <div className="z-20 absolute top-[19%] left-[65%]  font-neris-semibold">
                    <p className='text-sm font-rosvelit'>Origin</p>
                    <p className='text-sm'>COLOMBIA</p>
                </div>
                <div className='z-20 absolute w-[7%] top-[39%] left-[55%]'>
                    <img 
                        src="https://silver-stream-bucket.s3.amazonaws.com/Product_cup.svg" 
                        alt="country" 
                        className="w-full h-full"
                    />
                </div>
                <div className="z-20 absolute left-[65%] top-[43%] font-neris-semibold ">
                    <p className='text-xs font-rosvelit'>Taste and Aroma</p>
                    <div className='text-sm'>
                        <p>Velvety,</p>
                        <p>Sun-Kissed,</p>
                        <p>Mystique</p>
                    </div>
                </div>

                <div className='z-20 absolute w-[4%] top-[74%] left-[58%]'>
                    <img 
                        src="https://silver-stream-bucket.s3.amazonaws.com/Product_altitude.svg" 
                        alt="country" 
                        className="w-full h-full"
                    />
                </div>
                <div className="z-20 absolute top-[77%] left-[65%] font-neris-semibold">
                    <p className='text-sm font-rosvelit'>Altitude</p>
                    <p className='ml-4 text-sm'>5,740 ft.</p>
                </div>
            </div>

            {/* Flex Container for Arabica Icon and Shop Now Button */}
            <div className="flex justify-between items-center -mb-1 md:mb-20  mt-8">

                {/* Wrapper for Arabica Icon */}
                <div className="w-1/4 h-24 ml-[10%]"> 
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/arabica_icon_brown.svg" alt="Arabica Icon" className="w-full h-full mt-0 md:mt-8" />
                </div>

                {/* Spacer to push the button to the right */}
                <div className="flex-grow"></div>

                {/* Shop Now Button */}
                <div className="mr-[22%] justify-center">
                    <button className="bg-green w-full text-gold py-1 px-4 md:px-8 lg:px-12 max-w-xs rounded-full font-neris-light mb-4" onClick={() => navigate('/mountain-sunrise')}>
                        Shop Now
                    </button>
                </div>
            </div>

            {/* Product Description */}
            <div className="text-left space-y-6 py-8 md:py-4 max-w-4xl mx-auto font-neris-thin leading-relaxed text-justify">
                <p>Welcome to Silver Stream Café: your key to unlocking the extraordinary. With each new day comes a treasure trove of untapped potential, and our artisanal brews are here to help you discover it. Stimulate your senses, ignite your intellect, and unleash a wave of creativity that you never knew existed within you.</p>
                
                <p>At Silver Stream, we seamlessly blend coffee with inspiration, creating a luxury experience that fuels ambition. Every sip is a step towards uncharted heights, a spark setting your most brilliant ideas aflame.</p>
                
                <p>Remember: as you immerse yourself in our rich, tantalizing aroma, you’re doing more than just savoring a beverage. You’re embracing a philosophy. A philosophy that embodies the relentless pursuit of excellence, transforming the ordinary into the extraordinary.</p>
                
                <p>Welcome to Silver Stream Café. Dive into the experience, let your dreams take flight, and let your true potential shine through. <span className="font-neris-semibold">Elevate Your Day. Unlock Yourself.</span></p>
            </div>

        </div>
    );
}

export default ProductFeature;
