import React, { FormEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import contactUsStore from '../../stores/ContactUsStore';
import { Contact } from '../../types/ContactUs';

const ContactUs: React.FC = observer(() => {
    const { name, email, phone, message, status, setName, setEmail, setMessage, setPhone, canSubmit, createInquiry } = contactUsStore;
    const [formSubmitted, setFormSubmitted] = useState(false)

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (canSubmit) {
            const inquiryData: Contact = {
                name: name,
                email: email,
                message: message
            };
            if (phone.trim()) {  // Only add phone if it's not empty
                inquiryData.phone = phone;
            }
            await createInquiry(inquiryData);
        }
    };
    
        useEffect(() => {
            if (status === 'success') {
                setName('');
                setEmail('');
                setPhone('');
                setMessage('');
                setFormSubmitted(true)
                setTimeout(() => {
                    setFormSubmitted(false)
                }, 5000)
            }
        }, [setEmail, setMessage, setName, setPhone, status]);
         
    

    // Check if the required fields are filled out
    return (
        <div className="flex flex-col bg-gold justify-center space-y-10 p-10 relative">
            {/* Display success message if formSubmitted is true */}
            {formSubmitted && <div className="text-green font-neris-semibold text-center my-4">Your message has been sent successfully!</div>}
            {/* Contact Form and Decorative Images */}
            <div className="flex space-x-20 w-3/5 mx-auto">

                {/* Contact Form */}
                <div className="flex flex-col space-y-3 w-3/4 relative  ml-[-10%]">

                    {/* Title */}
                    <h2 className="text-3xl text-brown font-neris-semibold text-left mb-6">CONTACT US</h2>

                    <form className="space-y-6" onSubmit={handleSubmit}>
                        
                        {/* Full Name */}
                        <div>
                            <label htmlFor="name" className="sr-only">Name</label>
                            <input 
                                type="text" 
                                id="name" 
                                name="name" 
                                placeholder="Name" 
                                className="w-full p-3 rounded-full border border-gold"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>

                        {/* Email */}
                        <div>
                            <label htmlFor="email" className="sr-only">E-mail</label>
                            <input 
                                type="email" 
                                id="email" 
                                name="email" 
                                placeholder="Email" 
                                className="w-full p-3 rounded-full border border-gold"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        {/* Phone */}
                        <div>
                            <label htmlFor="phone" className="sr-only">Phone</label>
                            <input type="tel" id="phone" name="phone" placeholder="Phone (Optional)" className="w-full p-3 rounded-full border border-gold" 
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}/>
                        </div>

                        {/* Message */}
                        <div>
                            <label htmlFor="message" className="sr-only">Your Message</label>
                            <textarea 
                                id="message" 
                                name="message" 
                                rows={6} 
                                placeholder="Message" 
                                className="w-full p-4 rounded-3xl border border-gold"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                        </div>

                        {/* Send Button */}
                        <div className="w-full text-right mt-2">
                            <button 
                                disabled={!canSubmit} 
                                className={`px-6 py-1 ${canSubmit ? 'bg-brown' : 'bg-brown bg-opacity-50 cursor-not-allowed'} text-gold font-neris-light rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brown`}
                            >
                                Send Message
                            </button>
                        </div>
                    </form>

                    {/* Leaves and Cute Image - Positioned relative to the Contact Form */}
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/mountain_sunrise_leaf1.svg" alt="Leaf 1" className="absolute h-12 top-7 -right-[60%]" />
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/mountain_sunrise_leaf2.svg" alt="Leaf 2" className="absolute h-14 top-1/3 -right-[70%]" />
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/mountain_sunrise_leaf3.svg" alt="Leaf 3" className="absolute h-12 top-0 -right-[70%]" />
                    <img src="https://silver-stream-bucket.s3.amazonaws.com/product_cute_1.svg" alt="cute 1" className="absolute overflow-hidden bottom-[-50%]  -right-[100%]" />
                </div>
            </div>
        </div>
    );
});

export default (ContactUs);
