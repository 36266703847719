import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import ProductPrice, { Leaves, ProductDescription, ProductImages, QuantitySelector, WeightSelector } from './Components';
import CartPreview from '../Checkout/Cart/CartPreview';
import { cartStore } from '../../stores/CartStore';

const MountainSunrise = observer(() => {
    const [quantity, setQuantity] = useState(1); // Local state for handling quantity

    const handleWeightChange = (weight: number) => {
    };

    const handleAddToCartClick = () => {
        cartStore.addToCart({
            id: 'mountain-sunrise',
            name: 'Mountain Sunrise Coffee',
            price: cartStore.calculatePriceBasedOnWeight(360),
            quantity: quantity,
            weight: 360,
            imageUrl: 'https://silver-stream-bucket.s3.amazonaws.com/puch.svg'
        });
        setQuantity(1); // Reset quantity after adding to cart
    };

    const handleIncreaseQuantity = () => {
        setQuantity(quantity + 1);
    };

    const handleDecreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    return (
        <div className="flex flex-col bg-gold justify-center space-y-6 p-10 relative">
            <div className="absolute inset-0 z-0 overflow-hidden">
                <img src="https://silver-stream-bucket.s3.amazonaws.com/product_cute_1.svg" alt="coffee plant drawing" className="absolute md:mb-0 bottom-[-30%] w-[50%] h-auto lg:-right-20 md:-right-30 sm:-right-10" />
            </div>
            
            <div className="flex flex-col md:flex-row space-x-0 md:space-x-20 w-full md:w-3/5 mx-auto space-y-6 md:space-y-0">
                <ProductImages />

                <div className="flex w-full md:w-4/5 relative z-20">
                    <div className="flex flex-col space-y-4 w-4/5">
                        <ProductDescription />
                        <ProductPrice price={29} />

                        <WeightSelector selectedWeight={360} onWeightChange={handleWeightChange} />
                        <QuantitySelector quantity={quantity} increaseQuantity={handleIncreaseQuantity} decreaseQuantity={handleDecreaseQuantity} />

                        <button onClick={handleAddToCartClick} className="px-6 py-0 bg-green text-gold font-neris-light rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brown z-1">
                            Add to cart
                        </button>

                        <CartPreview />
                    </div>

                    <Leaves />
                </div>
            </div>
        </div>
    );
});

export default MountainSunrise;
